// extracted by mini-css-extract-plugin
export var content = "results-module--content--97e01";
export var description = "results-module--description--31f8c";
export var ratingSubtitle = "results-module--ratingSubtitle--1d371";
export var ratingTitle = "results-module--ratingTitle--ce66a";
export var results = "results-module--results--50f3b";
export var section1 = "results-module--section1--e31ac";
export var section2 = "results-module--section2--0cec3";
export var section3 = "results-module--section3--f33ad";
export var sections = "results-module--sections--92011";
export var title = "results-module--title--fc131";
export var vareResults = "results-module--vareResults--4df40";